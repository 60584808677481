

































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ObserveVisibility } from 'vue-observe-visibility';

import VideoPlayer from '@/components/VideoPlayer.vue';
import Awards from '@/components/Awards.vue';

@Component({
  components: {
    VideoPlayer,
    Awards,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
})
export default class Videos extends Mixins(View) {
}
